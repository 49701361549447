import React, { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from './firebase';
import { Layout } from "./components/dashboard/layout"; // Adjust the import path as needed
import { Avatar, Stack, Button, TextField, MenuItem, FormControl, Box, Typography, Autocomplete } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const BusManagement = () => {
  const [buses, setBuses] = useState([]);
  const [users, setUsers] = useState([]);
  const [chaperons, setChaperons] = useState([]);
  const [busNumber, setBusNumber] = useState('');
  const [chaperonId, setChaperonId] = useState('');
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [currentBusId, setCurrentBusId] = useState(null);

  useEffect(() => {
    fetchUsers();
    fetchBuses();
    setChaperons([{"id": "CHP001","name": "Jessy Nation V"},{"id": "CHP006","name": "Saratha K"},{"id": "CHP004","name": "Indhumathi T"},{"id": "CHP003","name": "Yegavalli T"},{"id": "CHP002","name": "Prasanna Devi M"}]);
  }, []);

  const fetchUsers = async () => {
    const userSnapshot = await getDocs(collection(db, 'users'));
    const usersList = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setUsers(usersList);
  };

  const fetchBuses = async () => {
    const busSnapshot = await getDocs(collection(db, 'buses'));
    const busList = busSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setBuses(busList);
  };

  //console.log(chaperons);

  const handleAddBus = async () => {
    if (editMode) {
      // Update existing bus
      const busRef = doc(db, 'buses', currentBusId);
      await updateDoc(busRef, {
        busNumber,
        chaperonId,
        students: selectedStudents.map(student => student.id),
      });
      setEditMode(false);
      setCurrentBusId(null);
    } else {
      // Ensure selected chaperon and students are not already assigned to another bus
      const isChaperonAssigned = buses.some(bus => bus.chaperonId === chaperonId);
      const areStudentsAssigned = selectedStudents.some(student =>
        buses.some(bus => bus.students.includes(student.id))
      );

      if (isChaperonAssigned) {
        alert('This chaperon is already assigned to another bus.');
        return;
      }

      if (areStudentsAssigned) {
        alert('One or more students are already assigned to another bus.');
        return;
      }

      // Add new bus
      const newBus = {
        busNumber,
        chaperonId,
        students: selectedStudents.map(student => student.id),
      };
      await addDoc(collection(db, 'buses'), newBus);
    }
    // Clear form
    setBusNumber('');
    setChaperonId('');
    setSelectedStudents([]);
    // Refresh bus list
    fetchBuses();
  };

  const handleDeleteBus = async (id) => {
    await deleteDoc(doc(db, 'buses', id));
    // Refresh bus list
    fetchBuses();
  };

  const handleEditBus = (bus) => {
    setBusNumber(bus.busNumber);
    setChaperonId(bus.chaperonId);
    setSelectedStudents(bus.students.map(studentId => users.find(user => user.id === studentId)));
    setEditMode(true);
    setCurrentBusId(bus.id);
  };

  const columns = [
    { field: 'busNumber', headerName: 'Bus Number', flex: 1 },
    { field: 'chaperonName', headerName: 'Chaperon', flex: 1 },
    { field: 'studentNames', headerName: 'Students', flex: 2 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <div>
          <Button variant="contained" color="primary" onClick={() => handleEditBus(params.row)}>Edit</Button>
          <Button variant="contained" color="secondary" onClick={() => handleDeleteBus(params.row.id)} style={{ marginLeft: '10px' }}>Delete</Button>
        </div>
      ),
    },
  ];

  const rows = buses.map(bus => ({
    ...bus,
    id: bus.id,
    chaperonName: users.find(user => user.id === bus.chaperonId)?.name || '',
    studentNames: bus.students.map(studentId => users.find(user => user.id === studentId)?.name).join(', '),
  }));

  return (
    <Layout>
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>Bus Management</Typography>
        <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '35ch' }, '& .MuiFormControl-root': { m: 1, width: '35ch' }, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <TextField
            label="Bus Number"
            value={busNumber}
            onChange={(e) => setBusNumber(e.target.value)}
            variant="outlined"
          />
          <FormControl variant="outlined">
            <TextField
              select
              label="Chaperon"
              value={chaperonId}
              onChange={(e) => setChaperonId(e.target.value)}
              variant="outlined"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {chaperons.map(chaperon => (
                <MenuItem key={chaperon.id} value={chaperon.id}>
                  {chaperon.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <FormControl variant="outlined">
            <Autocomplete
              multiple
              options={users.filter(user => user.role === 'student' && !buses.some(bus => bus.students.includes(user.id)))}
              getOptionLabel={(option) => option.name}
              value={selectedStudents}
              onChange={(event, newValue) => setSelectedStudents(newValue)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Students" placeholder="Select students" />
              )}
            />
          </FormControl>
          <Button onClick={handleAddBus} variant="contained" color="primary" sx={{ mt: 2 }}>
            {editMode ? 'Update Bus' : 'Add Bus'}
          </Button>
        </Box>
        <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
          <Typography variant="h5" gutterBottom>Existing Buses</Typography>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
          />
        </div>
      </Box>
    </Layout>
  );
};

export default BusManagement;
